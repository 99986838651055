@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');
h2{
  font-size: 15px;
  text-align: left;
  margin: 0;
}
.options{
  font-weight: 700;
  border-radius: var(--border-rad);
  background-color: white;
  height: 600px;
  width: 300px;
  border: 5px solid rgb(64, 119, 201);
  padding: 0 20px 0 20px ;
}
.options_form{
  font-weight: 400;
  display: flex;
  flex-direction: column;
}
.options_form div label{
  width: 65%;
  text-align: left;
}
.options_form div input{
  margin: 0 0 20px 0;
  width: 30%;
}
.option{
  display: flex;
  flex-direction: row;
}
.main_button_style{
  color: rgb(64, 119, 201);
  background-color: white;
  border: 5px solid rgb(64, 119, 201);
  border-radius: 10px;
  font-family: 'Oswald', sans-serif;
  width: 120px;
  height: 40px;
  margin: 20px auto 20px auto;
  cursor: pointer;
}
.find_way:hover{
  margin-bottom: 55px;
  margin-top: 15px;
  color: rgb(89, 142, 213);
  background-color: #ffffff;
  border: 5px solid rgb(89, 142, 213);
}
.options p{
  text-align: left;
}
.city_generation{
  margin-bottom: -1px;
}
.city_generation_button{
  background-color: rgb(64, 119, 201);
  color: white;
  border: 5px solid white;
  margin: 10px auto 10px auto;
}
.city_generation_button:active{
  width: 100px;
  height: 35px;
  margin-bottom: 15px;
}
.shortest_path{
  max-width: 100%;
}
.option label{
  position: relative;
}
