.city{
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  left:0;
  right: 0;
  z-index: 10;
  background-image: url("https://github.com/KNIDZE/ANTcolony/blob/main/images/pointer.png?raw=true");
  background-size: cover;
}
.city p{
  margin-top: -30px;
  margin-right: -2px;
  font-size: 20px;
  color: white;
}