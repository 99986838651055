.option_hint{
  padding: 20px 10px;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: -150px;
  top: -50px;
  width: 100px;
  border-radius: 20px;
  z-index: 5;
}
.hidden{
  display: none;
}
