:root{
  --border-rad: 20px;
}
body{
  font-family: 'Oswald', sans-serif;
}
.flex_column{
  display: flex;
  flex-direction: column;
}
.flex_row{
  display: flex;
  flex-direction: row;
}
.acp_section{
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  background-color: aquamarine;
  background-image: url("https://github.com/KNIDZE/ANTcolony/blob/main/images/light-blue-construction-paper-texture.jpg?raw=true");
  background-size: cover;
}
.fit_content{
  height: fit-content;
}
